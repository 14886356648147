<template>
  <div class="tagSetting c-bg-white">
    <h1 class="title ft-bold">标签设置</h1>
    <div class="tags">
      <el-tag
        :key="tag"
        v-for="tag in tagList"
        closable
        :disable-transitions="false"
        @close="handleClose(tag)">
        {{tag}}
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
    </div>
    <div class="con-btn"> <el-button type="primary" @click="confirm">确定</el-button></div>

  </div>
</template>

<script>
  import service from "./api";
  import { Message } from 'element-ui'
  export default {
    data(){
      return {
        tagList: [],
        inputVisible: false,
        inputValue: ''
      }
    },
    methods:{
      //初始化
      getList(){
        return service.getTags().then(res => {
          this.tagList = res.sys_reject_reason.split(',')
        });
      },
      handleClose(tag) {
        service.delTags({tag}).then(res => {
          if(!res.isHave){
            this.tagList.splice(this.tagList.indexOf(tag), 1);
          }else{
            this.$notify({message: '该标签已被商户使用，不能删除！', type:'warning', offset:'200'})
          }
        })
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let needClear = true;
        try {
          let inputValue = this.inputValue.trim();
          if (!inputValue)
            return;
          if (this.tagList.indexOf(inputValue) >= 0)
            return;
          if (!inputValue.match(/^[^,]*$/))
            throw new Error('不可输入特殊字符');
          this.tagList.push(inputValue);
        } catch (e) {
          needClear = false;
          Message.error(e.message);
        } finally {
          if (needClear) {
            this.inputVisible = false;
            this.inputValue = '';
          }
        }
      },
      confirm(){
        let list = this.tagList.join(',')
        service.updateTags({sys_reject_reason: list}).then(res => {
          this.$notify({ type: 'success', message: '操作成功！' });
          this.getList()
        })
      }
    },
    created(){
      this.getList()
    }
  }
</script>

<style lang="scss" scoped>
  .tagSetting{
    padding:20px;
    border-radius: 3px;
    text-align: left;
    /*min-height: 500px;*/
    .title{
      font-size: 20px;
      padding-bottom:10px;
      margin-bottom:20px;
      color:#222222;
      border-bottom:2px solid #efefef;
    }
    .el-tag + .el-tag {
      margin-left: 10px;
    }
    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
    .tags{
      padding:20px;
    }
    .con-btn{
      text-align:right;
      margin:20px 20px 20px 0;
    }
  }
</style>
