import API from "@/api/index";

export default{
  //获取标签
  getTags: params => {
    return API.requestPost(
      {
        url: "/api/v3/basic/query",
        data:{
          keys: "sys_reject_reason"
        }
      },
      false
    );
  },
  //
  updateTags: params =>{
    return API.requestPost(
      {
        url: "/api/v3/basic/update",
        data: params
      },
      false
    );
  },
  delTags: params =>{
    return API.requestPost(
      {
        url: "/api/v3/basic/queryTag",
        data: params
      },
      false
    );
  },
}
